import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ModuleIntro from './components/ModuleIntro';
import Slide from './components/Slide';
import Quiz from './components/Quiz';
import SummaryScreen from './components/SummaryScreen';
import './App.css';

const loadingMessages = [
  'Consulting the experts...',
  'Building the course...',
  'Almost ready...',
  'Putting on the finishing touches...'
];

function App() {
  const [course, setCourse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(loadingMessages[0]);
  const [error, setError] = useState(null);
  const [topic, setTopic] = useState('');
  const [stage, setStage] = useState('landing');
  const [currentModule, setCurrentModule] = useState(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [quizResults, setQuizResults] = useState(null);
  const [context, setContext] = useState('');

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setLoadingMessage(prevMessage => {
          const currentIndex = loadingMessages.indexOf(prevMessage);
          return loadingMessages[(currentIndex + 1) % loadingMessages.length];
        });
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [loading]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setCourse([]);

    try {
      const response = await axios.post('https://api.josh-yezek.com/api/generate-course', {
      // const response = await axios.post('http://localhost:5001/api/generate-course', {
        topic,
        context
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const module = response.data;
      setCourse([module]);
      setCurrentModule(module);
      setStage('moduleIntro');
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const nextModule = async () => {
    setLoading(true);
    setError(null);

    const lastModule = course[course.length - 1];
    // const newContext = `${context}\n${lastModule.title}: ${lastModule.introduction}`;
    console.log(quizResults.answers)
    const newContext = `${lastModule.title}: ${lastModule.introduction}, Quiz Results: 
    
    ${quizResults.answers.map((result, index) => (
`Result: ${result.yourAnswer === result.correctAnswer ? 'correct' : 'incorrect'}
Quesiton: ${result.question}
User answer: ${result.yourAnswer}
Correct answer: ${result.correctAnswer}
        
`
    ))}
    `
    ;

    try {
      // const response = await axios.post('http://localhost:5001/api/generate-course', {
        const response = await axios.post('https://api.josh-yezek.com/api/generate-course', {
        topic,
        context: newContext
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const module = response.data;
      setCourse([...course, module]);
      setCurrentModule(module);
      setStage('moduleIntro');
      setContext(newContext);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const startModule = (module) => {
    setCurrentModule(module);
    setStage('slides');
    setCurrentSlideIndex(0);
  };

  const nextSlide = () => {
    if (currentSlideIndex < currentModule.slides.length - 1) {
      setCurrentSlideIndex(currentSlideIndex + 1);
    } else {
      setStage('quiz');
    }
  };

  const prevSlide = () => {
    if (currentSlideIndex > 0) {
      setCurrentSlideIndex(currentSlideIndex - 1);
    }
  };

  const submitQuiz = (results) => {
    setQuizResults(results);
    setStage('summary');
  };

  return (
    <div className="App">
      <header>
        <h1>Learn Anything</h1>
      </header>
      <main>
        {loading && <div className="fullscreen loading-message">{loadingMessage}</div>}
        {!loading && stage === 'landing' && (
          <>
            <form onSubmit={handleSubmit}>
              <label>
                Enter a topic you would like to learn about:
                <input
                  type="text"
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                  required
                />
              </label>
              
              <button type="submit">Generate Course</button>
            </form>
            {error && <div>Error: {error.message}</div>}
          </>
        )}
        {!loading && stage === 'moduleIntro' && currentModule && (
          <ModuleIntro
            module={currentModule}
            startModule={startModule}
          />
        )}
        {!loading && stage === 'slides' && currentModule && (
          <div className="fullscreen">
            <Slide slide={currentModule.slides[currentSlideIndex]} />
            <div className="navigation">
              <button onClick={prevSlide} disabled={currentSlideIndex === 0}>Previous</button>
              <button onClick={nextSlide}>{currentSlideIndex === currentModule.slides.length - 1 ? 'Start Quiz' : 'Next'}</button>
            </div>
          </div>
        )}
        {!loading && stage === 'quiz' && currentModule && (
          <Quiz quiz={currentModule.quizzes} submitQuiz={submitQuiz} />
        )}
        {!loading && stage === 'summary' && quizResults && (
          <SummaryScreen results={quizResults} nextModule={nextModule} />
        )}
      </main>
      <footer>
        <p>Learn Anything © 2024</p>
      </footer>
    </div>
  );
}

export default App;

