import React from 'react';

function Slide({ slide }) {
  return (
    <div className="slide">
      <h3>{slide.title}</h3>
      <p>{slide.content}</p>
    </div>
  );
}

export default Slide;
