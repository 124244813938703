import React from 'react';

function ModuleIntro({ module, startModule }) {
  return (
    <div className="module-intro">
      <h1>{module.title}</h1>
      <p>{module.introduction}</p>
      <p>{module.overview}</p>
      <button onClick={() => startModule(module)}>Start</button>
    </div>
  );
}

export default ModuleIntro;



