import React, { useState } from 'react';
import SummaryScreen from './SummaryScreen';
import './Quiz.css';

function Quiz({ quiz, submitQuiz }) {
  const [selectedOptions, setSelectedOptions] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [results, setResults] = useState(null);

  const handleOptionChange = (question, option) => {
    setSelectedOptions({
      ...selectedOptions,
      [question]: option,
    });
  };

  const handleNext = () => {
    if (currentQuestionIndex < quiz.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    const results = quiz.map((q) => ({
      question: q.question,
      yourAnswer: selectedOptions[q.question],
      correctAnswer: q.answer,
    }));
    const score = results.filter((r) => r.yourAnswer === r.correctAnswer).length;
    submitQuiz({ score, answers: results });
    setResults({ score, answers: results });
    setShowResults(true);
  };

  const currentQuestion = quiz[currentQuestionIndex];

  return (
    <div className="quiz fullscreen">
      {showResults ? (
        <SummaryScreen results={results} />
      ) : (
        <div className="quiz-content">
          <p>{currentQuestion.question}</p>
          <ul className="quiz-options">
            {currentQuestion.options.map((option, idx) => (
              <li
                key={idx}
                className={`quiz-option ${selectedOptions[currentQuestion.question] === option ? 'selected' : ''}`}
                onClick={() => handleOptionChange(currentQuestion.question, option)}
              >
                {option}
              </li>
            ))}
          </ul>
          <button type="button" onClick={handleNext}>
            {currentQuestionIndex === quiz.length - 1 ? 'Submit' : 'Next'}
          </button>
        </div>
      )}
    </div>
  );
}

export default Quiz;


