import React from 'react';
import './SummaryScreen.css';

// function SummaryScreen({ results }) {
function SummaryScreen({ results, nextModule }) {
  if (!results) return null;

  return (
    <div className="summary-screen">
      <h2>Quiz Results</h2>
      {results.answers.map((result, index) => (
        <div
          key={index}
          className={`quiz-summary-item ${result.yourAnswer === result.correctAnswer ? 'correct' : 'incorrect'}`}
        >
          <p>{result.question}</p>
          <p>Your answer: {result.yourAnswer}</p>
          <p>Correct answer: {result.correctAnswer}</p>
        </div>
      ))}
      <p>Your score: {results.score} / {results.answers.length}</p>
      <button onClick={nextModule}>Next Module</button>
    </div>
  );
}

export default SummaryScreen;



